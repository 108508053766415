import React, { useState } from 'react';
import Tabs from '../../components/Tabs/Tabs';

import Hero from '../../components/Hero/Hero';

import { getPubPage } from './pub.query';

const Pub = () => {
  const data = getPubPage();

  const [currentTab, setCurrentTab] = useState('Drinki');

  const onTabChange = tab => {
    setCurrentTab(tab);
  };

  const {
    pubHeroHeading,
    pubHeroImages,
    pubDrinks,
    pubAlcohol,
    pubBeverages,
    pubSnacks,
    pubPizza,
  } = data.allWordpressPage.edges[0].node.acf;

  const menuItems = {
    Drinki: pubDrinks,
    Alkohole: pubAlcohol,
    Napoje: pubBeverages,
    Przekąski: pubSnacks,
    Pizza: pubPizza,
  };

  return (
    <>
      <Hero heading={pubHeroHeading} slides={pubHeroImages} />
      <Tabs
        currentTab={currentTab}
        handleTabChange={onTabChange}
        items={menuItems[currentTab]}
        categories={Object.keys(menuItems)}
      />
    </>
  );
};

export default Pub;
