import { useStaticQuery, graphql } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const getPubPage = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { id: { eq: "54bc065a-8db2-58d8-ad16-c57b26560947" } }) {
        edges {
          node {
            acf {
              pubHeroHeading
              pubHeroImages {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              pubDrinks {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              pubAlcohol {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              pubPizza {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              pubBeverages {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              pubSnacks {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
            }
          }
        }
      }
    }
  `);
